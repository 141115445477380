import React from "react";

interface Props {
  className?: string;
  fill: string;
  size: number;
}

function CheckIcon(props: Props): JSX.Element {
  return (
    <div {...props}>
      <svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.00039 16.1996L4.80039 11.9996L3.40039 13.3996L9.00039 18.9996L21.0004 6.99961L19.6004 5.59961L9.00039 16.1996Z"
          fill={props.fill}
        />
      </svg>
    </div>
  );
}

CheckIcon.defaultProps = {
  fill: "white",
  size: 24,
};

export { CheckIcon };
