import React from "react";
import { Colors } from "../utils/style-utils";

interface Props {
  fill: string;
  size: number;
}

function AddFileOutlineIcon(props: Props): JSX.Element {
  return (
    <div {...props}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="13.5" stroke={props.fill} />
        <line x1="14.166" y1="9" x2="14.166" y2="19" stroke={props.fill} />
        <line x1="19" y1="14.1665" x2="9" y2="14.1665" stroke={props.fill} />
      </svg>
    </div>
  );
}

AddFileOutlineIcon.defaultProps = {
  fill: Colors.Blue,
  size: 16,
};

export { AddFileOutlineIcon };
