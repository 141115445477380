import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
interface Fn<Args extends any[], Val> {
  (...rest: Args): Val;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useSafeCallback<Args extends any[], Val>(fn: Fn<Args, Val>): Fn<Args, Val> {
  const ref = React.useRef<Fn<Args, Val>>(fn);
  ref.current = fn;
  return React.useMemo(() => (...args) => ref.current.apply(null, args), []);
}