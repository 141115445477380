import { toPairs } from "ramda";

import { api } from "../utils/api";

export async function getSignedUrl(
  fileName: string,
  uuid: string,
  userId: number | string | null,
  requiresAuthentication = true
): Promise<{
  url: string;
  data: {
    fields: Record<string, string>;
    url: string;
  };
}> {
  const response = await api.request<{
    body: {
      url: string;
      data: {
        fields: Record<string, string>;
        url: string;
      };
    };
  }>("/sign-s3", {
    requiresAuthentication,
    method: "POST",
    body: { file_name: `${userId}/${uuid}/${fileName}` },
  });

  return response.body;
}

export async function uploadDocument(url: string, body: Record<string, string>, file: File): Promise<Response> {
  const fd = new FormData();
  const entries = toPairs(body);

  for (let i = 0; i < entries.length; i += 1) {
    const pair = entries[i];
    fd.append(pair[0], pair[1]);
  }

  fd.append("file", file, file.name);

  return fetch(url, {
    method: "post",
    body: fd,
  });
}

export async function processDocuments(
  modifiedBriefKey: string,
  sourceBriefKeys: string[],
  requiresAuthentication = true
): Promise<{ url: string }> {
  const response = await api.request<{ body: { url: string } }>("/process-documents", {
    body: { modified_brief_s3_key: modifiedBriefKey, source_brief_s3_key_list: sourceBriefKeys },
    method: "post",
    requiresAuthentication,
  });

  return response.body;
}
