import styled from "styled-components";

export const Label = styled.label`
  font-family: "Neue Haas", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #999;
  margin-bottom: 14px;
`;
