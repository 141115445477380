import React, { FC, useEffect } from "react";

import { RouteComponentProps } from "@reach/router";

import { Card } from "../components/Card";
import { PublicRoute } from "../components/PublicRoute";
import { SEO } from "../components/Seo";
import { H2, H4, Typography } from "../components/Typography";
import { UploadForm } from "../forms/UploadForm";
import { ViewportSize } from "../utils/media-queries-utils";
import { useUID } from "react-uid";
import { Colors } from "../utils/style-utils";

const Home: FC<RouteComponentProps> = (props) => {
  const id = useUID();

  useEffect(() => {
    if (typeof window !== "undefined" && props.location?.hash.substring(1) === "how-it-works") {
      const scrollToDiv = document.getElementById(id);
      scrollToDiv?.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.location]);

  return (
    <PublicRoute {...props}>
      <SEO title="Home" />
      <div className="flex min-h-full flex-col mt-40 mb-20 sm:mb-0 px-4 lg:flex-row lg:px-0">
        <div className="flex-1 flex flex-col items-center my-2 order-last lg:order-first">
          <Card className="mb-10 lg:mb-0">
            <UploadForm />
          </Card>
        </div>
        <div className="flex-1 flex flex-col justify-center order-first lg:order-last">
          <div className="mx-auto mb-20 lg:mx-0 lg:my-0" style={{ maxWidth: ViewportSize.mobileL }}>
            <H2 className="mb-6">Precedent analysis software for corporate transactions and documents.</H2>
            <Typography className="leading-loose">
              Colorline helps attorneys find, compare and redline precedents. While traditional redlines only allow 
              for one original document, Colorline has no limit. Insert any number of potential precedents and Colorline
              will find all the comps for each paragraph of your draft. 
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-20 mb-0 px-4 lg:flex-row lg:px-0" id={id}>
        <div className="flex-1 flex flex-col my-2 max-w-screen-lg mx-auto">
          <H4 color={Colors.Blue} className="leading-loose mb-4">
            Colorline helps with:
          </H4>
          <ul className="mb-4">
            <li>
              <Typography bold underline className="inline leading-loose">
                Comparing provisions
              </Typography>
              <ul className="pl-4 list-inside list-disc">
                <li>
                  <Typography className="inline leading-loose">
                    Uncertain if your language catches all the issues? View side-by-side comparisons for how each
                    precedent drafted a provision, and find the perfect language
                  </Typography>
                </li>
              </ul>
            </li>
            <li>
              <Typography bold underline className="inline leading-loose">
                Identifying precedents
              </Typography>
              <ul className="pl-4 list-inside list-disc">
                <li>
                  <Typography className="inline leading-loose">
                    Can’t find the right precedent? Colorline will find the closest precedent for each paragraph, with
                    the full suite of options
                  </Typography>
                </li>
              </ul>
            </li>
            <li>
              <Typography bold underline className="inline leading-loose">
                “Frankenstein” redlines
              </Typography>
              <ul className="pl-4 list-inside list-disc">
                <li>
                  <Typography className="inline leading-loose">
                    Redlining a draft that used multiple precedents? Colorline will mix and match the comparisons for
                    you, saving you hours
                  </Typography>
                </li>
              </ul>
            </li>
          </ul>

          <H4 color={Colors.Blue} className="inline leading-loose mb-4">
            How Colorline works:
          </H4>
          <ul className="list-inside list-disc mb-4">
            <li>
              <Typography className="inline leading-loose">Insert the precedents as original documents</Typography>
            </li>
            <li>
              <Typography className="inline leading-loose">Insert your draft as the modified document</Typography>
            </li>
            <li>
              <Typography className="inline leading-loose">For each paragraph in the modified document:</Typography>
              <ul className="pl-4 list-inside list-circle">
                <li>
                  <Typography className="inline leading-loose">
                    If a paragraph from any of the original documents matches, a comparison is displayed
                  </Typography>
                </li>
                <li>
                  <Typography className="inline leading-loose">
                    If multiple paragraphs match, separate comparisons are displayed
                  </Typography>
                </li>
                <li>
                  <Typography className="inline leading-loose">
                    If no paragraphs match, the paragraph is shown as is
                  </Typography>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </PublicRoute>
  );
};

export default Home;
