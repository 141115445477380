import { nanoid } from "nanoid";
import {
  assoc,
  curry,
  equals,
  filter,
  find,
  flatten,
  identity,
  ifElse,
  is,
  isNil,
  map,
  not,
  path,
  pipe,
  propEq,
  propSatisfies,
  values,
} from "ramda";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { v4 as uuidv4 } from "uuid";

import { ErrorMessage } from "@hookform/error-message";

import { getSignedUrl, processDocuments, uploadDocument } from "../api/documents";
import { signup } from "../api/me";
import { Anchor } from "../components/Anchor";
import { Button } from "../components/Button";
import { FileInput } from "../components/FileInput";
import { Input } from "../components/Input";
import { ProgressRing } from "../components/ProgressRing";
import { H4, H5, Typography } from "../components/Typography";
import { urlState } from "../contexts/forms";
import { CheckIcon } from "../icons/CheckIcon";
import { ErrorIcon } from "../icons/ErrorIcon";
import { idSelector } from "../selectors/me";
import { generateGuestToken, getBodyMessage, isEmptyOrNil, tryAutomaticDownload } from "../utils/function-utils";
import { Colors } from "../utils/style-utils";
import useLocalStorage from "../hooks/use-local-storage";
import { Loading } from "./styles";

enum DocumentType {
  Modified,
  Original,
}

interface Document {
  id: string;
  files: File[] | null;
  type: DocumentType;
}

interface FormValues {
  email: string;
}

enum UploadStep {
  Prepare,
  Upload,
  Process,
  Account,
  Success,
  Failure,
}

const isUploading = equals(UploadStep.Upload);
const isProcessing = equals(UploadStep.Process);
const isSuccess = equals(UploadStep.Success);
const isAccount = equals(UploadStep.Account);

const findModifiedDocument = find(propEq("type", DocumentType.Modified));
const findOriginalDocument = find(propEq("type", DocumentType.Original));
const excludeFileless = filter(propSatisfies(pipe(isEmptyOrNil, not), "files"));
const getValid = pipe(
  // @ts-ignore
  excludeFileless,
  map((document: Document) => document.files?.map((file) => ({ file, type: document.type }))),
  flatten,
  filter(Boolean)
);
const updateDocument = curry((id, file, collection) =>
  map(ifElse(propEq("id", id), assoc("files", file), identity))(collection)
);

function extractUrl(url: string) {
  return url.substring(url.indexOf("/") + 1);
}

export const UploadForm: FC = () => {
  const userId = useRecoilValue(idSelector);
  const { handleSubmit, register, errors } = useForm<FormValues>({ defaultValues: { email: "" } });
  const [temporaryEmail, setTemporaryEmail] = useLocalStorage("colorline:temporary-email", "");
  const [downloadLink, setDownloadLink] = useRecoilState(urlState);
  const successOrAccountButtonRef = useRef<HTMLButtonElement | null>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [formStep, setFormStep] = useState(UploadStep.Prepare);
  const [submitted, setSubmitted] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([
    { id: nanoid(8), files: null, type: DocumentType.Modified },
    { id: nanoid(8), files: null, type: DocumentType.Original },
  ] as Document[]);

  const isAnonymous = isNil(userId);
  const onFilesChange = useCallback(([id, fileList]: [string, File[] | undefined]) => {
    setDocuments(updateDocument(id, fileList));
  }, []);

  const modifiedDocument = findModifiedDocument(documents)! as Document;
  const originalDocument = findOriginalDocument(documents)! as Document;
  const [progress, setProgress] = useState(0);

  function valid() {
    return excludeFileless(documents).length === 2;
  }

  useEffect(() => {
    if (progress === 100) {
      if (isProcessing(formStep)) {
        if (isAnonymous && !temporaryEmail) {
          setFormStep(UploadStep.Account);
        } else {
          setFormStep(UploadStep.Success);
          tryAutomaticDownload(downloadLink);
        }

        successOrAccountButtonRef.current?.focus();
      }

      setProgress(0);
    }
  }, [progress]);

  const onSubmit = async (values: FormValues) => {
    let emailInUse = false;

    try {
      await signup(values);
      emailInUse = true;
    } catch (error) {
      const errorMsg = getBodyMessage(error);
      if (!errorMsg.includes("email is already in use")) {
        toast.error(errorMsg);
      } else {
        emailInUse = true;
      }
    } finally {
      if (emailInUse) {
        setTemporaryEmail(values.email);
        tryAutomaticDownload(downloadLink);
        resetForm();
      }
    }
  };

  useEffect(() => {
    async function upload() {
      if (isUploading(formStep)) {
        // @ts-ignore
        const validDocuments: { file: File; type: DocumentType }[] = getValid(documents);
        const anonUserId = `anonymous-${generateGuestToken()}`;
        const docsUuid = uuidv4();
        let modifiedBriefS3Key = null;
        let originalBriefS3KeyList = [] as string[];
        const len = validDocuments.length;
        const step = Math.round(100 / (len + 1));
        let error = false;

        for (let i = 0; i < len; i += 1) {
          const document = validDocuments[i];
          const modified = document.type === DocumentType.Modified;

          try {
            const result = await getSignedUrl(document.file!.name, docsUuid, userId || anonUserId, !isAnonymous);
            await uploadDocument(result.data.url, result.data.fields, document.file as File);

            if (modified) {
              modifiedBriefS3Key = result.url!.replace(/(^\w+:|^)\/\//, "");
            } else {
              originalBriefS3KeyList = [...originalBriefS3KeyList, result.url!.replace(/(^\w+:|^)\/\//, "")];
            }

            setProgress(Math.round(step * (i + 1)));
          } catch (err) {
            error = true;
            console.error(err);
          }
        }

        if (!modifiedBriefS3Key || originalBriefS3KeyList.length === 0) return;

        if (error) {
          toast.error(
            <span className="flex flex-row">
              <ErrorIcon className="mr-2" />
              Document could not be uploaded
            </span>
          );
          setFormStep(UploadStep.Prepare);
          setProgress(0);
        } else {
          setFormStep(UploadStep.Process);
        }

        try {
          const result = await processDocuments(
            extractUrl(modifiedBriefS3Key),
            originalBriefS3KeyList.map(extractUrl),
            !isAnonymous
          );
          setDownloadLink(result.url);
          setProgress(100);

          if (!isAnonymous) tryAutomaticDownload(result.url);
        } catch (err) {
          console.error(err);
          const serverMessage = path(["body", "message"], err);
          let messages;
          if (is(String, serverMessage)) {
            messages = [serverMessage];
          } else if (is(Object, serverMessage)) {
            messages = values(serverMessage as Record<string, string>);
          } else {
            messages = ["Document could not be processed"];
          }

          messages.forEach((message) =>
            toast.error(
              <span className="flex flex-row">
                <ErrorIcon className="mr-2" />
                {message}
              </span>
            )
          );
          setFormStep(UploadStep.Prepare);
          setProgress(0);
        }
      }
    }

    upload();
  }, [formStep]);

  function resetForm() {
    setDocuments([
      { id: nanoid(8), files: null, type: DocumentType.Modified },
      { id: nanoid(8), files: null, type: DocumentType.Original },
    ] as Document[]);
    setSubmitted(false);
    setFormStep(UploadStep.Prepare);
    setProgress(0);
    formRef.current?.reset();
  }

  function renderLoading() {
    if (isUploading(formStep) || isProcessing(formStep)) {
      return (
        <Loading fullSize className="flex flex-col items-center justify-center">
          <ProgressRing progress={progress} stroke={12} radius={110} />
          <H5 style={{ fontWeight: 600, fontSize: 18 }} className="mb-20 text-center">
            {isUploading(formStep) ? "Uploading..." : "Processing..."}
          </H5>
        </Loading>
      );
    }

    if (isSuccess(formStep)) {
      return (
        <Loading className="flex flex-col items-center justify-center">
          <H4 className="mb-12 text-center">Your files have been processed</H4>

          <CheckIcon fill={Colors.Blue} size={64} />

          <Typography className="text-center my-8">
            File will automatically download.
            <br />
            <br />
            Didnt get the file?{" "}
            <Anchor active href={downloadLink}>
              Download it.
            </Anchor>
          </Typography>

          <Button ref={successOrAccountButtonRef} fullWidth onClick={resetForm}>
            Start Over
          </Button>
        </Loading>
      );
    }

    if (isAccount(formStep)) {
      return (
        <Loading className="flex flex-col">
          <form onSubmit={handleSubmit(onSubmit)} className="relative flex-1 flex flex-col items-center justify-center">
            <H4 className="mb-12 text-center">Your files have been processed</H4>

            <CheckIcon fill={Colors.Blue} size={64} />

            <Typography className="text-center my-8">Please enter your email to download your file</Typography>

            <Input
              placeholder="Email"
              className={!!errors.email ? "mb-2" : "mb-4"}
              name="email"
              error={!!errors.email}
              fullWidth
              ref={register({
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Email is invalid",
                },
              })}
            />

            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => (
                <div className="flex flex-row justify-end mb-4 self-end">
                  <Typography error style={{ fontSize: 14 }}>
                    {message}
                  </Typography>
                </div>
              )}
            />

            <Button ref={successOrAccountButtonRef} fullWidth type="submit">
              Submit
            </Button>
          </form>
        </Loading>
      );
    }

    return null;
  }

  return (
    <div className="relative">
      <form
        className="relative"
        ref={formRef}
        onSubmit={(evt) => {
          evt.preventDefault();
          setSubmitted(true);
          if (valid()) setFormStep(UploadStep.Upload);
        }}
      >
        <div className="flex flex-col flex-1 bg-white w-full">
          <FileInput
            id={modifiedDocument.id}
            placeholder="Add modified file"
            label="Modified file"
            onChange={onFilesChange}
            onCancel={onFilesChange}
            error={submitted && isNil(modifiedDocument.files) ? "Please select a modified file" : ""}
          />

          <FileInput
            multiple
            id={originalDocument.id}
            placeholder={Number(originalDocument.files?.length) > 0 ? "Add more original files" : "Add original files"}
            label="Original files"
            onChange={onFilesChange}
            onCancel={onFilesChange}
            error={submitted && isNil(originalDocument.files) ? "Please select at least one original file" : ""}
          />

          <Button type="submit" fullWidth>
            Process
          </Button>
        </div>
      </form>
      {renderLoading()}
    </div>
  );
};
