import React from "react";
import { Colors } from "../utils/style-utils";

interface Props {
  fill: string;
  size: number;
}

function AddFileIcon(props: Props): JSX.Element {
  return (
    <div {...props}>
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="46" height="46" rx="23" fill={props.fill} />
        <line x1="23" y1="14" x2="23" y2="32" stroke="white" strokeWidth="2" />
        <line x1="32" y1="23" x2="14" y2="23" stroke="white" strokeWidth="2" />
      </svg>
    </div>
  );
}

AddFileIcon.defaultProps = {
  fill: Colors.Blue,
  size: 16,
};

export { AddFileIcon };
