import { prop } from "ramda";
import React from "react";
import styled from "styled-components";

interface Props {
  fill: string;
  hover?: string;
  size: number;
}

const HoverableIcon = styled.svg<{ hover: string }>`
  line {
    transition: stroke 0.3s ease-in-out;
  }
  &:hover line {
    stroke: ${prop("hover")};
    transition: stroke 0.3s ease-in-out;
  }
`;

function RemoveIcon(props: Props): JSX.Element {
  return (
    <div {...props}>
      <HoverableIcon
        hover={props.hover || "#999"}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="4.9511" y1="14.849" x2="14.8506" y2="4.94955" stroke={props.fill} strokeWidth="2" />
        <line x1="4.95125" y1="4.94963" x2="14.8507" y2="14.8491" stroke={props.fill} strokeWidth="2" />
      </HoverableIcon>
    </div>
  );
}

RemoveIcon.defaultProps = {
  fill: "black",
  hover: "#999",
  size: 16,
};

export { RemoveIcon };
