import styled from "styled-components";

import { Device, ViewportSize } from "../utils/media-queries-utils";

export const Card = styled.section`
  width: auto;
  min-width: ${ViewportSize.mobileL};
  background-color: white;
  position: relative;
  overflow: hidden;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 54px 36px;
  box-shadow: 0 16px 40px rgba(35, 45, 82, 0.08);
  display: flex;
  flex-direction: column;

  @media ${Device.mobileL} {
    min-width: 100%;
  }
`;
