import { createContext, useContext } from "react";
import { atom } from "recoil";

interface FormsContext {
  downloadUrl: string | null;
}

export const urlState = atom<string>({
  key: "downloadUrl",
  default: "",
});

export const FormsContext = createContext<FormsContext>({ downloadUrl: null });

export const useFormsContext = (): FormsContext => useContext(FormsContext);
