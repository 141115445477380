import styled from "styled-components";

export const Tooltip = styled.div`
  background: #000;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 16px;
  padding: 20px;
  max-width: 180px;
`;
