import { includes, toLower } from "ramda";
import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

interface P {
  multiple?: boolean;
  onDrop: (files: FileList) => void;
}

function checkFilesValidity(files: FileList) {
  let valid = true;

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    const [extension] = file.name.split(".").reverse();
    if (!includes(toLower(extension), ["pdf", "doc", "docx"])) {
      valid = false;
      break;
    }
  }

  return valid;
}

export const DragAndDrop: FC<P> = (props) => {
  const [dragging, setDrag] = useState(false);
  const counter = useRef<number>(0);
  const dropRef = useRef<HTMLDivElement>(null);

  function handleDrag(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  function handleDragIn(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    counter.current += 1;

    if (event.dataTransfer?.items && event.dataTransfer?.items.length > 0) {
      setDrag(true);
    }
  }

  function handleDragOut(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    counter.current -= 1;

    if (counter.current === 0) {
      setDrag(false);
    }
  }

  function handleDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    setDrag(false);

    if (event.dataTransfer?.files && event.dataTransfer?.files.length > 0) {
      if (event.dataTransfer.files.length > 1 && !props.multiple) {
        toast.info("Please drop only one file");
        return;
      }

      if (!checkFilesValidity(event.dataTransfer.files)) {
        toast.info("One or more files are not valid. Accepted extensions: PDF, DOC, DOCX");
        return;
      }

      props.onDrop(event.dataTransfer.files);
      event.dataTransfer.clearData();
      counter.current = 0;
    }
  }

  useEffect(() => {
    const div = dropRef.current as HTMLDivElement;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDrop);

    return () => {
      const div = dropRef.current as HTMLDivElement;
      div.removeEventListener("dragenter", handleDragIn);
      div.removeEventListener("dragleave", handleDragOut);
      div.removeEventListener("dragover", handleDrag);
      div.removeEventListener("drop", handleDrop);
    };
  }, []);

  return (
    <div className="inline-block relative w-full" ref={dropRef}>
      {(props.children as (props: unknown) => ReactElement | null)(dragging)}
    </div>
  );
};

DragAndDrop.defaultProps = {
  multiple: false,
};
