import { classd, classdFn } from "classd";
import { curry, isEmpty, isNil, map, pipe, prop, reject, remove } from "ramda";
import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  Fragment,
  InputHTMLAttributes,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { useUIDSeed } from "react-uid";
import styled from "styled-components";

import { AddFileIcon } from "../icons/AddFileIcon";
import { AddFileOutlineIcon } from "../icons/AddFileOutlineIcon";
import { ErrorIcon } from "../icons/ErrorIcon";
import { RemoveIcon } from "../icons/RemoveIcon";
import { Colors } from "../utils/style-utils";
import useSafeCallback from "../hooks/use-safe-callback";
import { DragAndDrop } from "./DragAndDrop";
import { Label } from "./Label";
import { Tooltip } from "./Tooltip";

export interface FileInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  label?: string;
  fullWidth?: boolean;
  onChange?: (x: [string, File[] | undefined]) => void;
  onCancel?: (x: [string, File[] | undefined]) => void;
  multiple?: boolean;
  error?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const Divider = styled.hr`
  border-color: rgba(0, 0, 0, 0.1);
  margin: 0 20px;
`;

const InputFacade = styled.div<{ multiple?: boolean }>`
  position: relative;
  cursor: pointer;
  height: 58px;
  background: #fafafa;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;

  ${Container}.empty & {
    height: 110px;
    border-radius: 6px;
  }

  ${Container}.multiple.filled & {
    border-top: 0 none;
    height: 73px;
  }

  &.dragging {
    border-style: dashed;
    border-color: ${Colors.Blue};
    background: white;
  }
`;

const InputContent = styled.span`
  color: ${Colors.Blue};
  position: absolute;
  cursor: pointer;
  font-weight: normal;
  font-family: "Neue Haas", sans-serif;
  font-size: 16px;
  color: #000;
  left: 24px;
  top: 18px;

  ${Container}.empty div & {
    left: 104px;
    top: 43px;
  }

  ${Container}.multiple.filled div & {
    font-weight: 300;
    color: ${Colors.Blue};
    left: 62px;
    top: 20px;
  }
`;

const Meta = styled.span`
  position: absolute;
  color: #999;
  top: 40px;
  left: 62px;
  font-size: 12px;
  cursor: pointer;
  font-weight: normal;
  font-family: "Neue Haas", sans-serif;
`;

const Ul = styled.ul`
  background: #fafafa;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  border-bottom: 0 none;
  max-height: 180px;
  overflow-y: auto;

  &.dragging {
    border-style: dashed;
    border-color: ${Colors.Blue};
    background: white;
  }
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  padding: 20px 25px;
  position: relative;
  font-family: "Neue Haas", sans-serif;
`;

const TextInput = styled.input.attrs<{ multiple?: boolean }>({
  className: "invisible w-full",
})`
  height: 58px;
  padding: 20px 25px;
`;

const ButtonContainer = styled.button.attrs({
  type: "button",
})`
  position: absolute;
  right: 22px;
  top: 20px;
  background: #fafafa;
  z-index: 10;

  ${Ul}.dragging &,
  ${Container}.dragging & {
    background: white;
  }
`;

const PlaceholderIcon = styled.div`
  position: absolute;
  z-index: 20;

  ${Container}.empty div & {
    left: 40px;
    bottom: 30px;
  }

  ${Container}.multiple.filled div & {
    left: 20px;
    bottom: 22px;
  }
`;

// @ts-ignore
const getFileNames: (x: (File | null)[]) => string[] = pipe(reject(isNil), map(prop("name")));
function mergeUnique(source: (File | null)[], target: File[]): File[] {
  const sourceFileNames = getFileNames(source);
  return [...source.filter(Boolean), ...target.filter((file) => !sourceFileNames.includes(file.name))] as File[];
}

const POPPER_ROOT = typeof document !== `undefined` ? document.getElementById("popper") : null;

export const FileInput: FC<FileInputProps> = ({
  label,
  onChange,
  onCancel,
  id,
  className,
  placeholder,
  multiple,
  error,
  ...rest
}) => {
  const seed = useUIDSeed();
  const ref = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const empty = isEmpty(files);
  const scrollTooltipRef = useRef(false);
  const [showScrollTooltip, setShowScrollTooltip] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles: errorStyles, attributes: errorAttributes } = usePopper(referenceElement, popperElement, {
    placement: "right",
    modifiers: [{ name: "offset", options: { offset: [0, 20] } }],
  });
  const { styles: infoStyles, attributes: infoAttributes } = usePopper(referenceElement, popperElement, {
    placement: "right",
    modifiers: [{ name: "offset", options: { offset: [-90, 20] } }],
  });

  useEffect(() => {
    setFiles(() => []);
  }, [id]);

  useEffect(() => {
    if (showScrollTooltip && !scrollTooltipRef.current) {
      const t = setTimeout(() => {
        setShowScrollTooltip(false);
        scrollTooltipRef.current = true;
      }, [2000]);

      return () => clearTimeout(t);
    }
  }, [showScrollTooltip]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onRemove = curry((index: number, _: MouseEvent) => {
    setFiles(remove(index, 1));
    onChange && onChange([id as string, index > -1 ? remove(index, 1, files) : undefined]);
  });

  const onDrop = useSafeCallback((fileList: FileList) => {
    if (ref.current) ref.current.files = fileList;
    const list = Array.from(fileList);

    if (Number(list!.length) === 0) {
      onChange && onChange([id as string, undefined]);
      setFiles(() => []);
      return;
    }

    const validFiles = multiple ? mergeUnique(files, Array.from(list!)) : Array.from(list!).slice(0, 1);

    onChange && onChange([id as string, validFiles]);
    setFiles(() => ([] as File[]).concat(validFiles));
    if (validFiles.length > 3) setShowScrollTooltip(true);
  });

  return (
    <Container className={classdFn({ empty: empty, multiple: multiple, filled: !empty })}>
      {label && <Label htmlFor={id}>{label}</Label>}

      <div className={classd("cursor-pointer relative w-auto", className)}>
        <PlaceholderIcon>{empty ? <AddFileIcon /> : multiple ? <AddFileOutlineIcon /> : null}</PlaceholderIcon>

        <DragAndDrop multiple={multiple} onDrop={onDrop}>
          {(dragging: boolean) => (
            <>
              {multiple && !empty && (
                <Ul className={classdFn({ dragging: dragging })}>
                  {files.map((file, index) => (
                    <Fragment key={seed(index)}>
                      <ListItem>
                        <span>{file.name}</span>
                        <ButtonContainer onClick={onRemove(index)}>
                          <RemoveIcon />
                        </ButtonContainer>
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))}
                </Ul>
              )}

              <InputFacade
                className={classdFn({ dragging: dragging })}
                onClick={() => ref.current && ref.current.click()}
                ref={setReferenceElement}
              >
                <InputContent>{empty || multiple ? placeholder : files[0]!.name}</InputContent>
                {!empty && multiple && <Meta>{files.filter(Boolean).length} file(s) added</Meta>}

                <TextInput
                  id={id}
                  multiple={multiple}
                  ref={ref}
                  type="file"
                  accept=".pdf,.doc,.docx"
                  onClick={(event: MouseEvent<HTMLInputElement>) => {
                    if ((event.target as HTMLInputElement).value.length === 0) {
                      onCancel && onCancel([id as string, undefined]);
                      setFiles(() => []);
                      // NOTE(zbrukas): we set to null here for the input to assume a new value always
                      // @ts-ignore
                      ref.current?.setAttribute("value", null);
                      return false;
                    }
                  }}
                  onBlur={(event: FocusEvent<HTMLInputElement>) => {
                    if (event.target.value.length === 0) {
                      onCancel && onCancel([id as string, undefined]);
                      setFiles(() => []);
                    }
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const list = event.target.files;

                    if (Number(list!.length) === 0) {
                      onChange && onChange([id as string, undefined]);
                      setFiles(() => []);
                      return;
                    }

                    const validFiles = multiple ? mergeUnique(files, Array.from(list!)) : Array.from(list!).slice(0, 1);

                    onChange && onChange([id as string, validFiles]);
                    setFiles(() => ([] as File[]).concat(validFiles));
                    if (validFiles.length > 3) setShowScrollTooltip(true);
                  }}
                  {...rest}
                />
              </InputFacade>

              {error &&
                POPPER_ROOT &&
                createPortal(
                  <Tooltip ref={setPopperElement} style={errorStyles.popper} {...errorAttributes.popper}>
                    <ErrorIcon />
                    <span className="mt-2" style={{ fontWeight: 600 }}>
                      Error!
                    </span>
                    <p>{error}</p>
                  </Tooltip>,
                  POPPER_ROOT
                )}

              {showScrollTooltip &&
                POPPER_ROOT &&
                createPortal(
                  <Tooltip ref={setPopperElement} style={infoStyles.popper} {...infoAttributes.popper}>
                    <span style={{ fontWeight: 600 }}>Scroll to see all files</span>
                  </Tooltip>,
                  POPPER_ROOT
                )}
            </>
          )}
        </DragAndDrop>

        {!empty && !multiple && (
          <ButtonContainer onClick={onRemove(-1)}>
            <RemoveIcon />
          </ButtonContainer>
        )}
      </div>
    </Container>
  );
};

FileInput.defaultProps = {
  label: "",
  fullWidth: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
  multiple: false,
};
