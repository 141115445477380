import React, { FC } from "react";
import styled from "styled-components";
import { H5 } from "./Typography";

const Percentage = styled(H5)`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 20px;
  margin: -15px 0px 0px -30px;
  z-index: 3;
  width: 60px;
  text-align: center;
  z-index: 3;
`;

interface ProgressRingProps {
  radius: number;
  stroke: number;
  progress: number;
}

export const ProgressRing: FC<ProgressRingProps> = ({ radius, stroke, progress }) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="relative" style={{ width: radius * 2 }}>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke="white"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + " " + circumference}
          style={{ strokeDashoffset: 0 }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke="#1032AC"
          fill="transparent"
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeDasharray={circumference + " " + circumference}
          style={{ strokeDashoffset, transform: 'rotate(-90deg)', transformOrigin: 'center', transformBox: 'fill-box' }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <Percentage>{progress}%</Percentage>
    </div>
  );
};
